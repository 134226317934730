import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import glue1 from '../../images/solution-glue2.jpg'
import glue2 from '../../images/solution-glue3.jpg'
import glue3 from '../../images/solution-glue4.jpg'
import glue4 from '../../images/solution-glue5.jpg'
import glue5 from '../../images/solution-glue6.jpg'

export default class SolutionList extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={3}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='胶耘-数字橡胶产业平台' />
        <div className='core-tech-wrap solution-wrap solution-wrap-glue'>
          <div className='main-img'></div>

          <div className='main-con' style={{ paddingBottom: '0px' }}>
            <h2>平台概述</h2>
            <p className='description-text'>
              2020年12月9日易见纹语科技联合云南省天然橡胶协会发布了基于“数耘”科技平台的“胶耘”数字橡胶产业平台。该平台利用全球
              领先的AI+区块链溯源科技，实现橡胶的上下游产品溯源、数字仓储服务、可信交易、供应链金融支撑等服务。截止到目前，“胶耘”平
              台上已经有近40家橡胶相关企业。未来通过与更多的专业检测机构、科研单位合作，“胶耘”将进一步为橡胶产业提供质量溯源、质量
              检测、质量提升等平台服务能力。因此，它是一个真正服务于橡胶产业各方，帮助企业拓宽业务，解决融资难、融资贵的难题，提升橡胶
              加工能力和质量竞争力，合力把橡胶产业做大、做强的平台。“十四五”期间是橡胶行业转型升级、实现高质量发展的关键5年，易见纹
              语科技借助工业互联网和数字科技，努力实现橡胶产业生产、加工、销售、仓储物流全过程的数字化升级和数据资源共享，提升企业整体
              效率，促成产业新业态、新模式，助力橡胶产业实现数字化转型升级。
            </p>

            <div className='trace-source' style={{ textAlign: 'center' }}>
              <img style={{ width: '60%', marginTop: '5rem' }} src={glue1} />
            </div>
          </div>

          <div className='main-con'>
            <h2>平台架构图</h2>
            <div className='trace-source'>
              <img src={glue2} alt='平台架构图' />
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap'>
          <img src={glue3} />
        </div>
        <div className='core-tech-wrap solution-wrap solution-module'>
          <div className='main-con'>
            <div className='trace-source'>
              <img src={glue4} />
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap'>
          <img src={glue5} />
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      solutions {
        id
        title
        image_link
        short_description
      }
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
